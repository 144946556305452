<template>
  <div class="mail" v-if="!isProcessing">
    <mail-menu class="user__menu" v-for="item in items" :key="item.title" :item="item" />
  </div>
</template>

<script>
import MailMenu from '@/components/common/menu.vue'

export default {
  name: 'mail',
  components: { MailMenu },
  data () {
    return {
      items: [
        { title: '全ユーザーに送信', content: '全ユーザーにメールを送信する', to: 'mail_all' }
      ]
    }
  },
  mounted () {
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.mail {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
}
</style>
